html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  --accentPrimary: #64CEF0;
  --alert: #fced9f;
  --backgroundLight: #F7F7F7;
  --colorPrimary1: #2A4E6E;
  --colorPrimary2: #041F37;
  --colorPrimary3: #C2D0DD;
  --colorPrimary4: #dee6ed;
  --colorReportBait: #53c653;
  --colorReportOrganelle: #2A4E6E;
  --colorReportPrey: #ff0055;
  --colorTableEven: #eeeeee;
  --defaultBorderColor: #b3b3b3;
  --fontDark: #333;
  --fontLight: #F2F2F2;
  --fontStackPrimary: Lato, sans-serif;
  --fontStackSecondary: Roboto, sans-serif;
  --fontSystem: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --success: #43a047;
  --timingFunction: cubic-bezier(.17, .67, .16, .99);
  --warning: #f44336;
  color: var(--fontDark);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

noscript {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

