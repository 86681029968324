.about {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.about__funding {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.about__funding a {
  margin: 10px;
}
.about__inner {
  max-width: 800px;
  text-align: justify;
}