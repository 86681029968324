.home__buttons .home__button {
  align-items: center;
  border-color: var(--colorPrimary1);
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  background-color: var(--backgroundLight);
  color: var(--fontDark);
  cursor: pointer;
  display: flex;
  font-size: 1em;
  font-family: var(--fontStackSecondary);
  justify-content: center;
  min-width: 200px;
  padding: 8px 6px 6px 6px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: auto;
}

.home__buttons .home__button > svg {
  margin-right: 4px;
}

.home__buttons .home__button:focus,
.home__buttons .home__button:hover {
  box-shadow: 0 0 3px #ccc;
  background-color: #C2D0DD;
  color: var(--fontDark);
  outline: 0;
  text-decoration: none;
}