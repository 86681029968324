.not-found {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px 30px;
}
.not-found h1 {
  line-height: 1.2em;
  text-align: center;
}
.not-found p {
  margin-top: 0; 
  max-width: 600px;
  text-align: center;
}

.not-found__link {
  font-size: 1.5em;
}