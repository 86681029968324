.home {
  background-image: url('./assets/cellmap.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  padding: 1rem;
}

.home__buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}
.home__buttons > * {
  margin: 0 5%;
}

.home__copyright {
  bottom: 1%;
  left: auto;
  position: fixed;
  right: 2%;
  text-align: right;
}

.home__description {
  background-color: rgba(245, 245, 245, 0.7);
  border-radius: 3px;
  margin-top: 1.5rem;
  max-width: 700px;
  padding-top: 0.7rem;
  text-align: center;
}

.home__help-icon {
  display: inline-block;
  font-size: 1.2rem;
  margin: 0 5px;
}

.home__inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
}
.home__inner p {
  font-size: 0.9rem;
  margin-top: 0;
}

.home__intro {
  margin-left: 20%;
}

.home__intro > h1 {
  font-size: 1.8rem;
  font-family: var(--fontStackSecondary);
  font-weight: 500;
}
.home__intro > h2 {
  font-size: 1.3rem;
  font-weight: normal;
}

@media (max-width: 1200px) {
  .home__intro {
    margin-left: 0;
  }
}
@media (max-width: 1000px) {
  .home {
    background-size: cover;
  }
}
@media (max-width: 799px) {
  .home {
    background-image: url('./assets/cellmap-small.jpg');
    justify-content: center;
  }

  .home__description {
    display: none;
  }

  .home__copyright {
    text-align: center;
    width: 100%;
  }

  .home__inner {
    flex-grow: 0;
  }
  
  .home__intro {
    background-color: rgba(245, 245, 245, 0.7);
    border-radius: 3px;
    margin-bottom: 20%;
    text-align: center;
  }
  .home__intro > h2 {
    font-size: 1.1rem;
  }
}